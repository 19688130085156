import React from 'react'
import Layout from "../components/App/Layout"
import NavbarThree from "../components/App/NavbarThree"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import EventDetailsContent from '../components/Events/EventDetailsContent'
import EventSpeakers from '../components/Events/EventSpeakers'

const EventDetails = ({location}) => {
    return (
        <Layout path={location.pathname}>
            <NavbarThree />
            <PageBanner
                pageTitle="Evénements"
                homePageText="Menu principal"
                homePageUrl="/" 
                activePageText="Evénements Partenaires"
            />
            <EventDetailsContent />
            <EventSpeakers />
            <Footer />
        </Layout>
    );
}

export default EventDetails;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;